

<template>

    <v-toolbar color="indigo-darken-4" dark >
         <v-btn  href="/"  color="white" style="color: #000 !important; background-color: #fff">
                     Voltar
        </v-btn>
                <v-toolbar-title>Meu NF-e / Detalhe NF-e</v-toolbar-title>

                
    </v-toolbar>


<v-card :title="CNPJ" :subtitle="NOME_EMPRESA" style="padding: 10px" >


<br>
 <h3>Produtos</h3>
<v-list lines="one">
  <v-list-item
    v-for="n in PRODUTOS"
    :key="n"
    :title="n.NOME"
  >
  Quantidade: {{n.QTD}}<br>
  CÓDIGO: {{n.CODE}}<br>
  VALOR DO PRODUTO: {{n.VALOR}}<br>
  </v-list-item>
</v-list>
<br>

<h3>VALOR TOTAL: R$ {{ VALOR_TOTAL }}</h3> <br>
<h3>VALOR DE DESCONTO: R$ {{ TOTAL_DESCONTO }}</h3> <br>
<h3>TOTAL PAGO: R$ {{ TOTAL_PAGO }}</h3>

<br>

<v-container>
    <v-row align="center" justify="center">
   
      <v-col cols="auto">
        <v-btn prepend-icon="mdi-open-in-new" :href="URL" target="_blank" color="primary">
                     Acessar Nota Receita
        </v-btn>
      </v-col>
    </v-row>
  </v-container>




</v-card>

      
</template>
<script>
import api from '@/services/api.js';


  export default {


data() {
    return { 
        loading: null,
        teste: null,
        NOME_EMPRESA: null,
        CNPJ: null,
        VALOR_TOTAL: null,
        TOTAL_DESCONTO: null,
        TOTAL_PAGO: null,
        PRODUTOS: null,
        URL: null,
        items: []
    }
},
    mounted() {
      this.trazerUrlQrCode();
    },
methods: {

trazerUrlQrCode(){
    
    const urlParams = new URLSearchParams(window.location.search);
    const modo = urlParams.get("id")

   api.get('/retornar-nfe-por-id/' + modo)
    .then( (response) => {
     this.loading = "loading2"
  this.items = response.data.corpo;
      this.NOME_EMPRESA = this.items.INFO.EMPRESA;
      this.CNPJ = this.items.INFO.CNPJ;
      this.VALOR_TOTAL = this.items.INFO.VALOR_TOTAL;
      this.TOTAL_DESCONTO = this.items.INFO.TOTAL_DESCONTO;
      this.TOTAL_PAGO = this.items.INFO.TOTAL_PAGO;
      this.PRODUTOS = this.items.PRODUTO;
      this.URL = this.items.URL;
  })
  .catch(function (error) {
     this.loading = "loading2"
      swal("Opa..", "Houve um erro ao tentar trazer os dados. Tente novamente atualizando a pagina.", "error");
    console.log(error)
  })



    }

}










  }
</script>


<style>

.botao-fab {
  position:fixed !important;
  width:80px;
  height:80px;
  bottom:30px;
  right:10px;
  background-color:#1a237e  !important;
  color:#fff !important;
  border-radius:50px;
  text-align:center;
  font-size:80px;
  box-shadow: 1px 1px 2px #888;
  z-index:1000;
}
</style>

