import { createApp } from 'vue'
import App from './App.vue'

import QrReader from 'vue3-qr-reader';
import * as VueRouter from 'vue-router';

import '@/db/local_db';
import '@/global.css'

import Home from '@/views/HomeView'
import DetalheNfe from '@/views/DetalheNfe'
import Inicio from '@/views/inicio'
import './registerServiceWorker'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})

const routes = [
    {
    path: "/",
    name: "home",
    component: Inicio,
},
{
    path: "/escanear",
    name: "escanear",
    component: Home,
},
{
    path: "/detalhe",
    name: "detalhe",
    component: DetalheNfe,
}
];
const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
});



createApp(App).use(vuetify).use(router).use(QrReader).mount('#app')


