<template>
 <v-card
    class="mx-auto"
  >
    <v-toolbar
      color="indigo-darken-4"
      dark
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Meu NF-e / QR Code</v-toolbar-title>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>

    
  </v-card>



<div :class="this.theme" id="container">

   <div :class="loading"></div>

    <div id="wholeContainer">

        <scan-qr id="positionScanQr" @gotNewScan="gotNewScan" 
                                    @newScan="this.last_scan = null; this.out_data = 'Scanning QR-Code...'" 
                                />

                               
      
    </div>


</div>
</template>

<script>
import { curr_theme } from '@/db/session_db';

import ButtonSettings from '@/components/ButtonSettings.vue';
import ScanQr from '@/components/ScanQr.vue'
import TextFieldOut from '@/components/TextFieldOut.vue'
import TextFieldLastScan from '@/components/TextFieldLastScan.vue';
import ButtonNewQr from '@/components/ButtonNewQr.vue';

import ModalSettingVue from '@/modals/ModalSetting.vue';
import ModalCreateQrVue from '@/modals/ModalCreateQr.vue';
import swal from 'sweetalert';
import api from '@/services/api.js';

export default {
name: 'App',
components: {
    ButtonSettings,
    ScanQr,
    TextFieldOut,
    TextFieldLastScan,
    ModalSettingVue,
    ButtonNewQr,
    ModalCreateQrVue
}, 
data() {
    return { 
        loading: null
    }
}, 
methods: {
    gotNewScan(data) {
        this.out_data = data
        var today = new Date();
        console.log(this.out_data)
        this.loading = "loading"
        this.salvarUrl(this.out_data);
      // swal("Good job!", "You clicked the button!", "success");
    },
    salvarUrl(url){
  
   api.post('/salvar-url-nf', {
      ID_USUARIO: '1',
      URL:  url
    })
    .then( (response) => {
     this.loading = "loading2"
  
     if(response.data.corpo == 'Sucesso!'){
     

      swal("Pronto!", "QR code escaneado com sucesso. Só aguardar o processamento da sua NFe", "success").then((willDelete) => {
  window.location.href = "/";
});
      
     }else{
      swal("Opa..", "Houve um erro ao tentar escanear seu QR CODE. Tente novamente.", "error");
     }

  })
  .catch(function (error) {
     this.loading = "loading2"
      swal("Opa..", "Houve um erro ao tentar escanear seu QR CODE. Tente novamente.", "error");
    console.log(error)
  })



    }
}
}
</script>

<style scoped>
@media (min-aspect-ratio: 3/2) {
  body {
    background-color: #9ff; /* cyan */
  }
}

#topBar {
    position: relative;
    display: flex;
    margin-top: 30px;
}

h1 {
    text-align: center;
    width: calc(100% - 150px);
    transform: translateY(-30%);
}

#positionScanQr {
    position: relative;
    margin-top: 60px;
}

#positionOut {
    position: relative;
    left: 50%;
    margin-top: 20px;
    transform: translateX(-50%);
}

.lastscan-enter-from,
.lastscan-leave-to {
    opacity: 0;
}

.lastscan-enter-active,
.lastscan-leave-active {
    transition: opacity 0.5s ease;
}

#wholeContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 80vw;
    
} 


.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
