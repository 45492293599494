

<template>
  <v-card
    class="mx-auto"
  >
    <v-toolbar
      color="indigo-darken-4"
      dark
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Meu NF-e / Lista</v-toolbar-title>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>

<template v-if="items == null">
  <v-list lines="two">
      
        <v-list-subheader >
          Nenhuma nota escaneada
        </v-list-subheader>

        <v-divider ></v-divider>
</v-list>
</template>


<template v-if="items != null">
    <v-list lines="two">
      
        <v-list-subheader >
          Notas Escaneadas 
        </v-list-subheader>

        <v-divider ></v-divider>


<template v-for="item in items">


<v-list lines="one">
        <v-list-item
        :key="item"
    :title="item.TITULO"
          :prepend-avatar="require('@/assets/nfe.jpg')"
          ripple
          :to="'/detalhe?id=' + item.ID_NFUR"
        >
     
       
           <span v-if="item.STATUS == 0">Em Processamento</span>
           <span v-if="item.STATUS == 1">Processado</span>
       
        </v-list-item>

   </v-list>

  <v-divider ></v-divider>

      </template>
    </v-list>

</template>






  </v-card>

        
                <v-btn
                  icon="$plus"
                  class="botao-fab"
                  to="/escanear"
                >
                
                </v-btn>
     
      
</template>
<script>
import api from '@/services/api.js';


  export default {


data() {
    return { 
        loading: null,
        items: []
    }
},
    mounted() {
      this.trazerUrlQrCode();
    },
methods: {

trazerUrlQrCode(){
  
   api.get('/lista-url-nf/1')
    .then( (response) => {
     this.loading = "loading2"
  this.items = response.data.corpo;
  })
  .catch(function (error) {
     this.loading = "loading2"
      swal("Opa..", "Houve um erro ao tentar trazer os dados. Tente novamente atualizando a pagina.", "error");
    console.log(error)
  })



    }

}










  }
</script>


<style>

.botao-fab {
  position:fixed !important;
  width:80px;
  height:80px;
  bottom:30px;
  right:10px;
  background-color:#1a237e  !important;
  color:#fff !important;
  border-radius:50px;
  text-align:center;
  font-size:80px;
  box-shadow: 1px 1px 2px #888;
  z-index:1000;
}
</style>

